import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { config } from '_helpers/config';
import _ from 'lodash';
import { docsActions } from '_actions/docs.actions';
import { toast } from 'react-toastify';
import { FormNav } from './FormNav';
import { navigate, validate } from './formHandlers';
class PayDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      form: {
        disclaimer_check: false,
      },
      errors: {},
      form_edited: false,
    };
    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.navigate = navigate.bind(this);
    this.validate = validate.bind(this);
    this.goBack = this.goBack.bind(this);
    this.form = React.createRef();
  }

  handleFormChange(e) {
    if (e.target && e.target.attributes['field']) {
      let field = e.target.attributes['field'].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;
      if (type === 'checkbox') {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen('sidebar');
          this.navigate('next', this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate('next', this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;
        // spread the data coming from API into state
        this.setState({
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }

    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1
      );
    }

    this.setState({ doc });
    this.getFormData(doc.id);
  }

  render() {
    let { form } = this.state;
    let { adminMode } = this.props;

    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title">
          <img
            src={`${config.assetUrl}icons/doc-title-img.svg`}
            className="doc-title-img"
          />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Pay Dates 2025</h3>
        </div>
        <div className="doc_info-form paydates">
          <form
            className="info-form"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            noValidate
          >
            <div className="row form-row">
              <div className="col">
                {/*custom data table*/}
                <div className="data_table dates">
                  <div className="data_table-head">
                    <div className="item">PAY PERIOD #</div>
                    <div className="item">PAY PERIOD DATES</div>
                    <div className="item">PAY DATE</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">1</div>
                    <div className="item">December 15 – December 28</div>
                    <div className="item">Jan 3</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">2</div>
                    <div className="item">December 29 – January 11</div>
                    <div className="item">Jan 17</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">3</div>
                    <div className="item">January 12 - January 25</div>
                    <div className="item">Jan 31</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">4</div>
                    <div className="item">January 26 – February 8</div>
                    <div className="item">Feb 14</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">5</div>
                    <div className="item">February 9 – February 22</div>
                    <div className="item">Feb 28</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">6</div>
                    <div className="item">February 23 – March 8</div>
                    <div className="item">Mar 14</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">7</div>
                    <div className="item">March 9 – March 22</div>
                    <div className="item">Mar 28</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">8</div>
                    <div className="item">March 23 – April 5</div>
                    <div className="item">Apr 11</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">9</div>
                    <div className="item">April 6 – April 19</div>
                    <div className="item">Apr 25</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">10</div>
                    <div className="item">April 20 – May 3</div>
                    <div className="item">May 9</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">11</div>
                    <div className="item">May 4 – May 17</div>
                    <div className="item">May 23</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">12</div>
                    <div className="item">May 18 – May 31</div>
                    <div className="item">Jun 6</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">13</div>
                    <div className="item">June 1 – June 14</div>
                    <div className="item">Jun 20</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">14</div>
                    <div className="item">June 15 – June 28</div>
                    <div className="item">Jul 3</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">15</div>
                    <div className="item">June 29 – July 12</div>
                    <div className="item">Jul 18</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">16</div>
                    <div className="item">July 13 – July 26</div>
                    <div className="item">Aug 1</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">17</div>
                    <div className="item">July 27 – August 9</div>
                    <div className="item">Aug 15</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">18</div>
                    <div className="item">August 10 – August 23</div>
                    <div className="item">Aug 29</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">19</div>
                    <div className="item">August 24 – September 6</div>
                    <div className="item">Sep 12</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">20</div>
                    <div className="item">September 7 – September 20</div>
                    <div className="item">Sep 26</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">21</div>
                    <div className="item">September 21 – October 4</div>
                    <div className="item">Oct 10</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">22</div>
                    <div className="item">October 5 – October 18</div>
                    <div className="item">Oct 24</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">23</div>
                    <div className="item">October 19 – November 1</div>
                    <div className="item">Nov 7</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">24</div>
                    <div className="item">November 2 – November 15</div>
                    <div className="item">Nov 21</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">25</div>
                    <div className="item">November 16 – November 29</div>
                    <div className="item">Dec 5</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">26</div>
                    <div className="item">November 30 – December 13</div>
                    <div className="item">Dec 19</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">1</div>
                    <div className="item">December 14 – December 27</div>
                    <div className="item">Jan 2, 2026</div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row">
              <div className="col">
                <div className="data_table holidays">
                  <div className="data_table-head">
                    <div className="item">HOLIDAY</div>
                    <div className="item">DAY</div>
                    <div className="item">DATE</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">New Years Day</div>
                    <div className="item">Wed</div>
                    <div className="item">1/1/25</div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">Memorial Day</div>
                    <div className="item">Mon</div>
                    <div className="item">5/26/25 </div>
                  </div>
                  <div className="data_table-info">
                    <div className="item">Independence Day</div>
                    <div className="item">Fri</div>
                    <div className="item">7/4/25</div>
                  </div>

                  <div className="data_table-info">
                    <div className="item">Labor Day</div>
                    <div className="item">Mon</div>
                    <div className="item">9/1/25 </div>
                  </div>

                  <div className="data_table-info">
                    <div className="item">Thanksgiving Day</div>
                    <div className="item">Thu</div>
                    <div className="item">11/27/25</div>
                  </div>

                  <div className="data_table-info">
                    <div className="item">Thanksgiving Travel Day</div>
                    <div className="item">Fri</div>
                    <div className="item">11/28/25</div>
                  </div>

                  <div className="data_table-info">
                    <div className="item">Christmas</div>
                    <div className="item">Thu</div>
                    <div className="item">12/25/25</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row form-row left-align payments-note">
              <div className="col">
                <div className="d-flex flex-column">
                  <p className="text-center">
                    Holidays are available after 90‐day probation. <br />
                    Must work the week of the holiday unless preapproved for
                    time off.
                    <br /> Please refer to the Employee Handbook for additional
                    company policies on holiday pay.
                  </p>
                </div>
                <div className="d-flex flex-column">
                  <h3 className="text-center">
                    NOTE: Effective January 1, 2025 minimum wage is $16.50 per
                    hour
                  </h3>
                </div>
              </div>
            </div>

            <div className="row form-row">
              <div className="col">
                <div className="disclaimers">
                  <strong className="diss">Disclaimer: </strong>You must read
                  and carefully understand the documents that you are filling
                  out and signing. These are legally binding documents and must
                  be accurate. By filling these forms out, you are agreeing to
                  our{' '}
                  <a href="">
                    Terms of Use, Privacy Policy and Employee Agreements.
                  </a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="form.disclaimer_check"
                        checked={form.disclaimer_check}
                      />
                      <span className="checkbox_checkbox"></span>
                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate}
              disclaimer_check={form.disclaimer_check}
              lastPage={this.props.lastPage}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedPayDates = compose(withRouter, connect(mapStateToProps))(PayDates);
export { connectedPayDates as PayDates };
